import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e3c0184e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
import { useRouter } from "vue-router";
import domain from "@/service/domain";
import { onBeforeMount } from "vue";
export default {
  setup(__props) {
    let list = [{
      name: "营业执照",
      father_id: "1",
      son_id: "14"
    }, {
      name: "增值电信业务经营许可证：京B2-20192895",
      father_id: "1",
      son_id: "9"
    }, {
      name: "出版物经营许可证：新出发京零字第石180042号",
      father_id: "1",
      son_id: "11"
    }, {
      name: "食品经营许可证：JY11107052343981",
      father_id: "1",
      son_id: "17"
    }, {
      name: "营业性演出许可证：京演（机构）【2019】4218号",
      father_id: "1",
      son_id: "13"
    }];
    onBeforeMount(() => {
      document.title = '资质证照';
    });
    const router = useRouter();
    function goProtocal(item) {
      const url = `${domain.wap}/protocal/${item.father_id}/${item.son_id}`;
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: url,
        webFun: () => {
          router.push({
            path: `/protocal/${item.father_id}/${item.son_id}`,
            query: {}
          });
        }
      });
    }
    return (_ctx, _cache) => {
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_cell_group, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, index) => {
          return _openBlock(), _createBlock(_component_van_cell, {
            key: index,
            title: item.name,
            "is-link": "",
            onClick: $event => goProtocal(item)
          }, null, 8, ["title", "onClick"]);
        }), 128))]),
        _: 1
      })]);
    };
  }
};